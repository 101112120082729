import React, { useState } from "react"
import { Link, graphql } from "gatsby"
import Scroll from '../components/scroll'

import Bio from "../components/bio"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"
import CustomReactShare from "../components/customShareBlock"
import { createMuiTheme, MuiThemeProvider, makeStyles } from '@material-ui/core/styles'
import ResumeSideBar from '../components/resumeSidebar'
import Disqus from 'gatsby-plugin-disqus'
import Subscribe from '../components/subscribeHubspot'
import ClapButton from '../components/ClapButton'
import Loader from '../components/loader'
import ComingSoon from '../components/comingSoon'

import Image from "gatsby-image"

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 568,
      md: 760,
      lg: 960,
      xl: 1200,
    }
  }
});

const useStyles = makeStyles((theme) => ({
  featuredImageContainer: {
    height: '40vh'
  },
  featuredImage: {
    maxHeight: '100%',
    maxWidth: '100%',
    width: "100",
    height: "100",

    [theme.breakpoints.up('xs')]: {
    },
    [theme.breakpoints.up('sm')]: {

    },
    [theme.breakpoints.up('md')]: {

    },
    [theme.breakpoints.up('lg')]: {

    },
  },
  articles: {
    [theme.breakpoints.up('xs')]: {
      paddingTop: '18%',
      marginLeft: '7.5%',
      marginRight: '7.5%'
    },
    [theme.breakpoints.up('sm')]: {
      paddingTop: '12%',
      marginLeft: '8%',
      marginRight: '8%'
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: '10%',
      marginLeft: '10%',
      marginRight: '10%'
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: '9%',
      marginLeft: '15%',
      marginRight: '15%'
    },
  },
  comments: {
    [theme.breakpoints.up('xs')]: {
      marginLeft: '7.5%',
      marginRight: '7.5%'
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: '8%',
      marginRight: '8%'
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: '10%',
      marginRight: '10%'
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: '15%',
      marginRight: '15%'
    },
  },
  btn: {
    [theme.breakpoints.up('xs')]: {
      fontSize: '12px'
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '18px'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '22px'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '26px'
    },
    backgroundColor: '#16D5DF',
    color: 'black',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#16D5DF',
      color: 'black'
    },
    marginLeft: '30%',
    marginTop: '7%'
  },
  subscribe: {
    padding: '2%'
  },
  applauseButton: {
    [theme.breakpoints.up('xs')]: {
      float: 'right',
      marginTop: '12%',
      marginRight: '5%'
    },
    [theme.breakpoints.up('sm')]: {
      float: 'right',
      marginTop: '9%',
      marginRight: '8%'
    },
    [theme.breakpoints.up('md')]: {
      float: 'right',
      marginTop: '7%',
      marginRight: '11%'
    },
    [theme.breakpoints.up('lg')]: {
      float: 'right',
      marginTop: '6%',
      marginRight: '17%'
    },
  }
})
)

const BlogPostTemplate = ({ data, pageContext, location }) => {

  const classes = useStyles();

  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title
  const { previous, next } = pageContext
  const image = post.frontmatter.image
    ? post.frontmatter.image.childImageSharp.resize
    : null
  const slug = post.fields.slug

  const identify = slug + siteTitle

  const [loadingIndicator, setLoadingIndicator] = useState(true)

  return (
    <div>
      {loadingIndicator &&
        <Loader load={loadingIndicator} setLoad={setLoadingIndicator} />
      }
      <MuiThemeProvider theme={theme} >
        <div style={
          {
            display: loadingIndicator ? 'none' : 'block'
          }
        }>
          {/* <ComingSoon /> */}
          <div>
            <ResumeSideBar />
            <SEO
              title={post.frontmatter.title}
              image={image}
              description={post.frontmatter.description || post.excerpt}
              pathname={location.pathname}
            />
            <div className={classes.wholePage}>
              <div className={classes.applauseButton}>
                <ClapButton slug={slug} color={'black'} />
              </div>
              <article className={classes.articles} style={
                {
                  minHeight: '85vh',
                }
              }>
                <header>
                  <h1
                    style={{
                      marginTop: rhythm(-1),
                      marginBottom: 0,
                    }}
                  >
                    {post.frontmatter.title}
                  </h1>
                  <p
                    style={{
                      fontSize: '16px',
                      // ...scale(-1 / 5),
                      display: `block`,
                      marginBottom: rhythm(1),
                    }}
                  >
                    {post.frontmatter.date}
                  </p>
                </header>
                <div>
                  <Image fluid={post.frontmatter.image.childImageSharp.fluid}
                    style={{
                      marginBottom: rhythm(1),
                      width: '100%',
                      height: 'auto',
                    }}
                  />
                </div>
                <section style={{
                  fontSize: '16px'
                }}
                  dangerouslySetInnerHTML={{ __html: post.html }} />
                <hr
                  style={{
                    marginBottom: rhythm(1),
                  }}
                />
                <footer>
                  <Bio />
                </footer>
                <nav>
                  <ul
                    style={{
                      display: `flex`,
                      flexWrap: `wrap`,
                      justifyContent: `space-between`,
                      listStyle: `none`,
                      padding: 0,
                      marginTop: 0
                    }}
                  >
                    <li>
                      {previous && (
                        <Link to={previous.fields.slug} rel="prev">
                          ← {previous.frontmatter.title}
                        </Link>
                      )}
                    </li>
                    <li>
                      {next && (
                        <Link to={next.fields.slug} rel="next">
                          {next.frontmatter.title} →
                        </Link>
                      )}
                    </li>
                  </ul>
                </nav>
                <div
                  style={
                    {
                      textAlign: 'center',
                      justifyContent: 'center',
                      marginBottom: 0
                    }
                  }
                >
                  <Subscribe />
                </div>

                <Scroll showBelow={250} css='position: fixed; right: 1.5em; bottom: 1em;' />
                <div>
                  <h4
                    style={
                      {
                        marginTop: '3%',
                        textAlign: 'center',
                        justifyContent: 'center'
                      }
                    }>
                    Share this blog
            <CustomReactShare title={`${siteTitle} - ${post.frontmatter.title}`} excerpt={post.frontmatter.description || post.excerpt} url={`${data.site.siteMetadata.siteUrl}${post.fields.slug}`} text='#article via @ailuj876' />
                  </h4>
                </div>
              </article>
            </div>
            <div className={classes.comments}>
              <Disqus
                identifier={identify}
                title={post.frontmatter.title}
                url={`https://juliapottinger.com${slug}`}
              />
            </div>
          </div>
          <div
            style={
              {
                textAlign: 'center',
                justifyContent: 'center',
                paddingBottom: '2%',
              }
            }>
            © {new Date().getFullYear()} Julia Pottinger - All Rights Reserved
      </div>
        </div>
      </MuiThemeProvider>
    </div>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        social {
          twitter
        }
        siteUrl
      }
    }
    markdownRemark(fields: {slug: {eq: $slug}}) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        tags
        image: featured {
          childImageSharp {
            resize(width: 1200) {
              src
              height
              width
            }
            fluid(maxWidth: 1000, maxHeight: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      fields{
        slug
      }
    }
  }
`
